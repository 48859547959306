<template>
    <validation-observer tag="div" ref="observer">
        <b-overlay :show="loading" no-wrap></b-overlay>
        <div v-if="!loading">

            <b-row>
                <b-col cols="12" class="text-right">
                    <b-button
                        @click="$root.$children[0].openModal('kbbuchung-modal', {id: 0}, afterSave, {width: '800px'})"
                        variant="primary"
                        class="mb-2"
                    >
                        <font-awesome-icon icon="plus"/>
                        {{ $t('common.button.create') }}
                    </b-button>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="12">

                    <b-table-simple striped>
                        <b-thead head-variant="info">
                            <b-th>{{ $t('settings.kbbuchungs.label.id') }}</b-th>
                            <b-th>{{ $t('settings.kbbuchungs.label.title') }}</b-th>
                            <b-th>{{ $t('settings.kbbuchungs.label.kbkonto_title') }}</b-th>
                            <b-th>{{ $t('settings.kbbuchungs.label.tax') }}</b-th>
                            <b-th>{{ $t('settings.kbbuchungs.label.created_at') }}</b-th>
                            <b-th>{{ $t('settings.kbbuchungs.label.actions') }}</b-th>
                        </b-thead>
                        <draggable :class="{[`cursor-grabbing`]: dragging === true}" v-model="kbbuchungs"
                                   group="columns" @start="dragging = true" @end="dragging = false" tag="tbody"
                                   handle=".handle">
                            <b-tr v-for="kbbuchung in kbbuchungs" :key="kbbuchung.id">
                                <b-td>{{ kbbuchung.id }}</b-td>
                                <b-td>{{ kbbuchung.title }}</b-td>
                                <b-td>{{ kbbuchung.kbkonto.title }}</b-td>
                                <b-td>{{ kbbuchung.tax }}%</b-td>
                                <b-td>{{ kbbuchung.created_at | formatDate('DD.MM.YYYY HH:mm:ss') }}</b-td>
                                <b-td>
                                    <b-button
                                        @click="$root.$children[0].openModal('kbbuchung-modal', {id: kbbuchung.id},afterSave,{width: '800px'})"
                                        size="sm"
                                        variant="primary"
                                        class="mr-2"
                                        :title="$t('common.title.edit')"
                                    >
                                        <font-awesome-icon icon="edit"/>
                                    </b-button>

                                    <b-button @click="deleteKbbuchung(kbbuchung.id)"
                                              size="sm"
                                              variant="danger"
                                              :title="$t('common.title.delete')"
                                    >
                                        <font-awesome-icon icon="trash"/>
                                    </b-button>
                                </b-td>
                            </b-tr>
                        </draggable>
                    </b-table-simple>
                </b-col>
            </b-row>
        </div>
    </validation-observer>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'
import Draggable from "vuedraggable"

export default {
    name: 'Settings-kbbuchung',
    components: {
        Draggable
    },
    mixins: [commonSave],
    data() {
        return {
            loading: true,
            kbbuchungs: [],
            dragging: false,
        }
    },
    methods: {
        ...mapGetters('Kbbuchung', ['getKbbuchungs']),
        shown() {
            const kbbuchungsFetch = this.$store.dispatch('Kbbuchung/fetchKbbuchungs')

            Promise.all([kbbuchungsFetch])
                .then(() => {
                    this.kbbuchungs = this.getKbbuchungs()
                })
                .finally(() => {
                    this.loading = false
                })
        },
        afterSave() {
            this.$store.dispatch('CommonData/fetchCommonData')
            this.shown()
            this.$root.afterSettingsUpdate()
        },
        deleteKbbuchung(id) {
            this.$removeConfirm('Kbbuchung/deleteKbbuchung', id, this.shown)
        }
    },
    mounted() {
        this.shown()
    }
}
</script>